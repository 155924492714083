<template>
  <img alt="Vue logo" src="./assets/mindless.png" style="width: 200px; margin-top: 80px" />
  <!-- zhangjifu.cn -->
  <!-- <Main msg="黑ICP备16003321号-7" /> -->

  <!-- geeph.me,geephzhang.com:8888:GeephZhang -->
  <!-- <Main msg="黑ICP备16003321号-1" /> -->

  <!-- jifuzhang.com,jifuzhang.cn,zhangjifu.com,geeph.tech:6666:JifuZhang -->
  <Main msg="黑ICP备16003321号-4" />
</template>

<script>
import Main from "./components/index.vue";

export default {
  name: "App",
  components: {
    Main,
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: rgba(22, 22, 41, 0.7);
}

html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
